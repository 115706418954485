import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
        <div>
      <div dangerouslySetInnerHTML={{ __html: `

<nav class="navbar navbar-light light-blue lighten-4 d-sm-none">

    <a class="navbar-brand" href="/">
    <img src="/uploads/voorlichtingsles-logo.png" height="40" alt="Voorlichtingsles Volwassen Worden">
  </a>
    
  
  <button class="navbar-toggler toggler-example" type="button" data-toggle="collapse" data-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
      <span class="dark-blue-text"><i class="fas fa-bars fa-1x"></i></span>
    </button>

  
  <div class="collapse navbar-collapse" id="navbarSupportedContent1">

    
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link waves-effect waves-light" href="/">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" href="/lessen">Lessen</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" href="/lesmaterialen">Lesmaterialen</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" href="/multimedia">Multimedia aanpak</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" href="/lesmaterialen/lespakket-gratis-bestellen">Lespakket gratis bestellen</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" href="/service">Service</a>
      </li>
    </ul>
    

  </div>
  

</nav>

    <header class="bg-blue d-none d-sm-block pt-3">

        <div class="container-fluid" style="border-bottom:rgb(238,38,110) 7px solid">
            <div class="container">
                <div class="row hideMegaMenu">
                    <div class="col-12">
                        <img src="/uploads/voorlichtingsles-logo.png" alt="Voorlichtingsles Volwassen Worden">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <a class="show-menu" href="/" data-value="-1"><i class="fas fa-home fa-2x"></i></a>
                        <a class="show-menu" href="/lessen" data-value="0">Lessen</a>
                        <a class="show-menu" href="/lesmaterialen" data-value="1">Lesmaterialen</a>
                        <a class="show-menu" href="/multimedia" data-value="2">Multimedia aanpak</a>
                        <a class="show-menu" href="/lesmaterialen/lespakket-gratis-bestellen" data-value="3">Lespakket gratis bestellen</a>
                        <a class="show-menu" href="/service" data-value="4">Service</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="megamenu container-fluid" data-show="0" style="z-index:99999;position:absolute;display:none">
            <div id="megamenu_0" class="container">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-md-3">
                            <img src="/uploads/layer_1_richtlijnen.jpg" class="img-fluid" alt="Richtlijnen">
                                <a href="/lessen/richtlijnen" class="d-block mt-1 tab-menu stretched-link">Richtlijnen voor de seksuele voorlichting</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_1_lesvoorbereiding.jpg" class="img-fluid" alt="Lesvoorbereiding">
                                <a href="/lessen/lesvoorbereiding" class="d-block mt-1 tab-menu stretched-link">Lesvoorbereiding</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_1_tips.jpg" class="img-fluid" alt="Tips">
                                <a href="/lessen/tips" class="d-block mt-1 tab-menu stretched-link">Tips voor de seksuele voorlichtingslessen</a>
                        </div>
                    </div>
                </div>
                <div class="container-fluid borderd"></div>
            </div>
            <div id="megamenu_1" class="container">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-md-4">
                            <ul class="nav navigatie">
                                <li class="font-weight-bold"><a href="/lesmaterialen/themagebieden" style="color:inherit">Themagebieden</a>
                                    <div>
                                        <a href="/lesmaterialen/themagebieden/ontwikkeling-en-puberteit" class="d-block mt-1 tab-menu">Ontwikkeling en puberteit</a>
                                        <a href="/lesmaterialen/themagebieden/geslachtsorganen" class="d-block mt-1 tab-menu">Geslachtsorganen</a>
                                        <a href="/lesmaterialen/themagebieden/cyclus-en-menstruatie" class="d-block mt-1 tab-menu">Menstruatiecyclus</a>
                                        <a href="/lesmaterialen/themagebieden/menstruatie-hygine" class="d-block mt-1 tab-menu">Menstruatie hygi&#xEB;ne</a>
                                        <a href="/lesmaterialen/themagebieden/tampongebruik" class="d-block mt-1 tab-menu">Tampongebruik</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-4">
                            <ul class="nav navigatie">
                                <li class="font-weight-bold"><a href="/lesmaterialen/speciale-pakketten" style="color:inherit">Speciale pakketten</a>
                                    <div>
                                        <a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-meisjes" class="d-block mt-1 tab-menu">Lesmateriaal voor meisjes</a>
                                        <a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens" class="d-block mt-1 tab-menu">Lesmateriaal voor jongens</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-4">

                            <ul class="nav navigatie">
                                <li class="font-weight-bold"><a href="/lesmaterialen/speciale-pakketten" style="color:inherit">Lespakket gratis bestellen</a>
                                    <div>
                                        <a href="/lesmaterialen/lespakket-gratis-bestellen/docentenhandleiding" class="d-block mt-1 tab-menu">Docentenhandleiding</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="container-fluid borderd"></div>
            </div>
            <div id="megamenu_2" class="container">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-md-3">
                            <img src="/uploads/layer_3_instructiefilm.jpg" class="img-fluid" alt="Instructiefilm">
                                <a href="/multimedia/instructiefilm-over-seksuele-voorlichting-volwassen-worden" class="d-block mt-1 tab-menu stretched-link">Instructiefilm over seksuele voorlichting &#xAB;Volwassen worden&#xBB;</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_3_kennistest.jpg" class="img-fluid" alt="Online kennistest">
                                <a href="/multimedia/online-kennistests" class="d-block mt-1 tab-menu stretched-link">Online kennistest</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_3_oefeningen.jpg" class="img-fluid" alt="Oefenbladen">
                                <a href="/multimedia/oefenbladen" class="d-block mt-1 tab-menu stretched-link">Oefenbladen</a>
                        </div>
                    </div>
                </div>
                <div class="container-fluid borderd"></div>
            </div>
            <div id="megamenu_3" class="container">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-md-3">
                            <img src="/uploads/layer_4_primaironderwijs.jpg" class="img-fluid" alt="Bestel nu gratis">
                                <a href="/lesmaterialen/lespakket-gratis-bestellen" class="d-block mt-1 tab-menu stretched-link">Bestel nu gratis</a>
                        </div>
                    </div>
                </div>
                <div class="container-fluid borderd"></div>
            </div>
            <div id="megamenu_4" class="container">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-md-3">
                            <img src="/uploads/layer_5_belangrijke_vragen.jpg" class="img-fluid" alt="Belangrijke vragen">
                                <a href="/service/veel-gestelde-vragen" class="d-block mt-1 tab-menu stretched-link">Belangrijke vragen over menstruatie en menstruatiehygi&#xEB;ne</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_5_belangrijke_links.jpg" class="img-fluid" alt="Service">
                                <a href="/service/belangrijke-links" class="d-block mt-1 tab-menu stretched-link">Belangrijke links</a>
                        </div>
                        <div class="col-md-3">
                            <img src="/uploads/layer_5_contact.jpg" class="img-fluid" alt="Contact">
                                <a href="/service/contact" class="d-block mt-1 tab-menu stretched-link">Contact</a>
                        </div>
                    </div>
                </div>
                <div class="container-fluid borderd"></div>
            </div>
        </div>
    </header>
    
    <main class="hideMegaMenu">
        <div class="container-fluid opening mb-3">            
            <div class="container bg-donker-groen">
                <div class="row">
                    <div class="col-md-6 px-4 py-3">
                        <h1 class="h1-responsive text-white">Lespakket gratis bestellen</h1>

                    </div>
                    <div class="col-md-6">
                        <img src="/uploads/img_4458_5_bestellen.jpg" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid hideMegaMenu">
            <div class="container">

	<div id="breadcrumb"><div class="bc my-3 d-none d-sm-block ">
	<a href="/">Home</a> &gt; <a href="/lesmaterialen">Lesmaterialen voor seksuele voorlichtingslessen</a> &gt; <a href="/lesmaterialen/lespakket-gratis-bestellen">Lespakket gratis bestellen</a>
</div>
</div>

                <div class="row">
                    <div class="col-md-8">
                        <div id="content">
		<h1 class="h1-responsive my-3  ">Lespakket gratis bestellen</h1>
         <p class="lead hide"></p>
        <p></p><p>Sinds 45 jaar biedt Johnson &amp; Johnson GmbH gratis lespakketten aan voor de seksuele voorlichtingslessen. Deze lespakketten worden in samenwerking met een college (of groep) van experts op het gebied van pedagogen en artsen ontwikkeld en regelmatig geactualiseerd.</p>
<p>Van het Gesellschaft fur &quot;Padagogik und Information e.V. (GPI) heeft het schoolprogramma 2018 al voor de vierde keer het Comenius-EduMedia Zegel ontvangen voor pedagogisch, inhoudelijk en creatief hoogwaardige digitale educatieve middelen.</p>

<a href="https://www.kenmerk.nl/lesprogramma/volwassen-worden-lespakket" target="_blank" class="btn bg-blauw text-white waves-effect waves-light">Ga naar onze webshop</a>

<div class="B00569">
<h3 class="mt-5">Overzicht van het lespakket Voorlichtingsles</h3>

<div class="row mt-3">
    <div class="col-md-3"><img src="/uploads/img_4469_film-volwassen-worden.png" class="img-fluid" alt="Introductiefilm" "volwassen worden">
    </div>
    <div class="col-md-9">
        <h5>Introductiefilm &quot;Volwassen worden&quot;</h5>
        <p>&#xA0;
Ervaring met puberteit en de eerste menstruatie vanuit het perspectief van een 12 &#xE0; 13 jarig meisje. Hier vind je ook materiaal voor de educatieve les met uitleg over belangrijke fysieke veranderingen bij meisjes en jongens.</p>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-3"><img src="/uploads/blb_4643_cover-handleiding.png" class="img-fluid" alt="Docentenhandleiding">
    </div>
    <div class="col-md-9">
        <h5>Docentenhandleiding</h5>
        <p>In de docentenhandleiding staan suggesties voor lessen over de puberteit en het gebruik van het leerlingenboekje, de film,&#xA0;het speciale boekje voor meisjes, het bekkenbodemprofiel, het speciale boekje voor jongens&#xA0;en de site. Ook geeft de handleiding extra informatie en antwoorden op de vragen en opdrachten van de lesmaterialen.</p>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-3"><img src="/uploads/blb_4579_leerlingboek.png" class="img-fluid" alt="Leerlingenboekje">
    </div>
    <div class="col-md-9">
        <h5>Leerlingenboekje</h5>
        <p>In het kleurrijke leerlingenboekje wisselen informatieve tekstgedeelten en opdrachten elkaar af. Met behulp van illustraties wordt uitgebreid verteld hoe een meisje en een jongen lichamelijk in elkaar zitten en wat er verandert in de puberteit. Meisjes worden bijvoorbeeld eerst ongesteld: een spannende gebeurtenis. Door de logische opbouw, heldere uitleg en de vele illustraties is de uitgave geschikt voor zowel klassikaal als individueel gebruik.</p>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-3"><img src="/uploads/blb_4581_speciaal_boekje_meisjes.jpg" class="img-fluid" alt="Speciale" boekjes voor meisjes + proefpakketjes o.b.® tampons>
    </div>
    <div class="col-md-9">
        <h5>Speciale boekjes voor meisjes + proefpakketjes o.b.&#xAE; tampons</h5>
        <p>Speciaal voor de meisjes is er ook een boekje waarin dieper wordt ingegaan op het onderwerp menstruatie. In eigentijdse&#xA0;bewoordingen wordt belangrijke informatie gegeven&#xA0;over menstruatie, lichaamsverzorging, het eigen lichaam, etc. Het boekje is bedoeld om mee naar huis te nemen. De meisjes kunnen thuis op hun gemak de informatie doornemen. Als u in de gelegenheid bent om de meisjes korte tijd apart te nemen, dan&#xA0;kunt u het boekje ook op school bespreken en behandelen.</p>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-3"><img src="/uploads/blb_4583_speciaal_boekje_jongens.jpg" class="img-fluid" alt="Speciaal" boekje voor de jongens>
    </div>
    <div class="col-md-9">
        <h5>Speciaal boekje voor de jongens</h5>
        <p>Speciaal voor de jongens is er een boekje waarin dieper wordt ingegaan op de veranderingen die jongens doormaken in de puberteit m.b.t. het eigen lichaam, lichaamsverzorging, etc. De jongens kunnen thuis op hun gemak de informatie doornemen.</p>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-3"><img src="/uploads/blb_4585_bekkenbodmeprofiel.png" class="img-fluid" alt="Bekkenbodemprofiel">
    </div>
    <div class="col-md-9">
        <h5>Bekkenbodemprofiel</h5>
        <p>Aan de hand van het demonstratiemodel kunt u o.a. de werking van een tampon uitleggen. Het bekkenbodemprofiel geeft een duidelijk beeld van de geslachtsorganen van de vrouw.</p>
    </div>
</div>
</div><p></p>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="bg-blue zij-menu p-3">
	<a href="/lesmaterialen" class="level-1 d-block ">Lesmaterialen voor seksuele voorlichtingslessen</a>
<a href="/lesmaterialen/themagebieden" class="level-2 d-block ">Themagebieden</a>
<a href="/lesmaterialen/themagebieden/ontwikkeling-en-puberteit" class="level-3 d-block ">Ontwikkeling en puberteit</a>
<a href="/lesmaterialen/themagebieden/geslachtsorganen" class="level-3 d-block ">Geslachtsorganen</a>
<a href="/lesmaterialen/themagebieden/cyclus-en-menstruatie" class="level-3 d-block ">Cyclus en menstruatie</a>
<a href="/lesmaterialen/themagebieden/menstruatie-hygine" class="level-3 d-block ">Menstruatie hygi&#xEB;ne</a>
<a href="/lesmaterialen/themagebieden/tampongebruik" class="level-3 d-block ">Tampongebruik</a>
<a href="/lesmaterialen/speciale-pakketten" class="level-2 d-block ">Speciale pakketten</a>
<a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-meisjes" class="level-3 d-block ">Lesmateriaal voor meisjes</a>
<a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens" class="level-3 d-block ">Lesmateriaal voor jongens</a>

<a href="/lesmaterialen/lespakket-gratis-bestellen" class="level-2 d-block text-rood">Lespakket gratis bestellen</a>
<a href="/lesmaterialen/lespakket-gratis-bestellen/docentenhandleiding" class="level-3 d-block ">Docentenhandleiding</a>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </main>


    
<div class="B00544">
    <div class="container-fluid pb-3">
        <div class="container">
            <div class="row mr-md-5 pr-md-5">
		<div class="col-md-4 text-center d-none">
    <a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens" class="stretched-link"><img src="/uploads/callout1_jongen.png" class="img-fluid p-3" alt="Materiaal voor jongens"></a>
    <h3>Materiaal voor jongens</h3>
    <div class="pay-off">
        Lesmateriaal rondom het thema puberteit bij jongens
    </div>
    <a href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Nu downloaden</a>
</div>
<div class="col-md-4 text-center ">
    <a href="/lesmaterialen/speciale-pakketten" class="stretched-link"><img src="/uploads/callout2_primair_onderwijs.png" class="img-fluid p-3" alt="Materiaal voor basisscholen"></a>
    <h3>Materiaal voor basisscholen</h3>
    <div class="pay-off">
        Lesmateriaal voor seksuele voorlichtingslessen
    </div>
    <a href="/lesmaterialen/speciale-pakketten" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Nu samenstellen</a>
</div>
<div class="col-md-4 text-center ">
    <a href="/lessen/lesvoorbereiding" class="stretched-link"><img src="/uploads/callout3_lesvoorbereiding.png" class="img-fluid p-3" alt="Hulp bij de lesvoorbereiding"></a>
    <h3>Hulp bij de lesvoorbereiding</h3>
    <div class="pay-off">
        Tips en checklists voor seksuele voorlichtingslessen
    </div>
    <a href="/lessen/lesvoorbereiding" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Nu informeren</a>
</div>
<div class="col-md-4 text-center ">
    <a href="/lesmaterialen" class="stretched-link"><img src="/uploads/callout5_lesmateriaal.png" class="img-fluid p-3" alt="Download lesmaterialen"></a>
    <h3>Download lesmaterialen</h3>
    <div class="pay-off">
        Uitgebreid aanbod van onderwerpen voor de seksuele voorlichting 
    </div>
    <a href="/lesmaterialen" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Naar de materialen</a>
</div>
<div class="col-md-4 text-center d-none">
    <a href="/multimedia" class="stretched-link"><img src="/uploads/callout6_multimedia.png" class="img-fluid p-3" alt="Multimedia voor seksuele voorlichting"></a>
    <h3>Multimedia voor seksuele voorlichting</h3>
    <div class="pay-off">
        Instructiefilm en interactieve kennistests voor seksuele voorlichting
    </div>
    <a href="/multimedia" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Nu bekijken</a>
</div>
<div class="col-md-4 text-center d-none">
    <a href="/lesmaterialen/lespakket-gratis-bestellen" class="stretched-link"><img src="/uploads/callout4_lespakket_bestellen.png" class="img-fluid p-3" alt="Gratis lespakket"></a>
    <h3>Gratis lespakket</h3>
    <div class="pay-off">
        Relevante lesmaterialen voor seksuele voorlichting
    </div>
    <a href="/lesmaterialen/lespakket-gratis-bestellen" class="btn text-white bg-dark-blue waves-effect waves-light mt-3">Nu bestellen</a>
</div>
            </div>
        </div>
    </div>

</div>
        ` }} />
            <Footer></Footer>
        </div>   


    );
  }
}

export default Page;

